import React, { useState, useMemo, useCallback, useEffect } from "react";

import { getTaxPayer } from "../../api/taxPayer/getTaxPayer/index.js";
import SendIcon from "@mui/icons-material/Send";
import { getAccrualPdf } from "../../api/accrual/getAcrrualPdf/index.js";
import QRModal from "../../components/QRModal/index.js";
import MailHistoryModal from "./mailHistoryModal.js";
import date from "date-and-time";
import FilterDrawer from "../../components/FilterDrawer/index.js";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import UnDeclaratedTaxesModal from "./UnDeclaratedTaxesModal.js";
import Swal from "sweetalert2";
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import DateRangePicker from "../../components/DateRangePicker/index.js";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import createColumnDefs from "./columnDefs.js";
import gmail from "../../gmail.png";
import localStorage from "local-storage";
import TaxPayerNotificationDetailsModal from "./TaxPayerNotificationDetailsModal.js";
import { sendDeclarationsToTaxpayer } from "../../api/declaration/sendDeclarationsToTaxpayer/index.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import ShowPdfModal from "../../components/showPdfModal/index.js";
import {
  Grid,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  Pagination,
  TextField,
  MenuItem,
  ButtonGroup,
  Button,
  Drawer,
  Box,
  Popover,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import localeTextTr from "../../locale.tr.js";
import { lighten, darken } from "polished";
import { getNotificationList } from "../../api/notification/getDeclarationList/index.js";
import { sendNotificationsToTaxpayer } from "../../api/notification/sendNotificationToTaxpayer/index.js";
import { markNotificationAsInvalid } from "../../api/notification/markNotificationAsInvalid/index.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";

function TaxPayerNotificationPage() {
  const [QR, setQR] = useState(null);
  const [message, setMessage] = useState(null);
  const [QRModalOpen, setQRModalOpen] = useState(false);
  const [selectedSGKData, setSelectedSGKData] = useState(null);
  const [openMailHistoryModal, setOpenMailHistoryModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [selectedPdfData, setSelectedPdfData] = useState(null);
  const [isPdfModalOpen, setPdfModalOpen] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reload, setReload] = useState(true);
  const [unDeclaratedTaxesModalOpen, setUnDeclaratedTaxesModalOpen] =
    useState(false);
  const [taxPayerData, setTaxPayerData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState({ name: null, sort: null });
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const [filters, setFilters] = useState({
    status: null,
    systemStatus: null,
    types: [],
    mailStatus: null,
  });

  useEffect(() => {
    setTimeout(clearLocalStorage, 10 * 60 * 1000);

    getTaxPayer(id)
      .then((resp) => {
        setTaxPayerData(resp.data.data);
        getNotificationList(resp.data.data?.id, currentPage, pageSize, sort)
          .then((resp) => {
            setData(resp.data.data);
            setTotalPage(resp.data.totalPage);
            setTotalCount(resp.data.totalCount);
          })
          .catch((err) => {
            Swal.fire({
              title: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      })
      .catch((error) => {
        // console.log(error.response.data, "burası olabilir");
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
          confirmButtonText: "Yenile",
          cancelButtonText: "Tamam",
          showCancelButton: true,
        }).then((response) => {
          if (response.isConfirmed) {
            setReload(!reload);
          }
        });
      });
  }, [reload, currentPage, pageSize, sort]);

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedIds = selectedNodes.map((node) => node.data.id);
    // console.log(selectedIds, "selectedIds");
    setSelectedIds(selectedIds);
  };

  useEffect(() => {
    const today = new Date();

    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");
    setEndDate(today);
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }

    const cleanupLocalStorage = () => {
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, []);
  function clearLocalStorage() {
    localStorage.clear();
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [rowData, setRowData] = useState();

  const isRowSelectable = useMemo(() => {
    return (rowNode) => {
      return rowNode.data
        ? rowNode.data.status === "Onaylandı" && rowNode.data.rDriveID
        : false;
    };
  }, []);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElAcrual, setAnchorElAcrual] = useState(null);
  const [anchorElDeclaration, setAnchorElDeclaration] = useState(null);

  const handleClickAcrual = (event) => {
    setAnchorElAcrual(event.currentTarget);
  };

  const handleClickDeclaration = (event) => {
    setAnchorElDeclaration(event.currentTarget);
  };

  const handleCloseAcrual = () => {
    setAnchorElAcrual(null);
  };

  const handleCloseDeclaration = () => {
    setAnchorElDeclaration(null);
  };

  const openAcrual = Boolean(anchorElAcrual);
  const openDeclaration = Boolean(anchorElDeclaration);

  const popoverIdAcrual = openAcrual ? "popoverAcrual" : undefined;
  const popoverIdDeclaration = openDeclaration
    ? "popoverDeclaration"
    : undefined;

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        defaultExpanded: false,
        // sideBar={gridSideBar},
        // rowSelection: "multiple",
        // suppressRowClickSelection: true,
        enableRangeSelection: true,
        // paginationAutoPageSize: true,
        columnDefs: [{ field: "description" }],
        defaultColDef: {
          flex: 3,
          maxWidth: 300,
          resizable: true,
          floatingFilter: true,
          suppressMenu: false, // Allow menu in detail grid columns
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.subDetails);
      },
    };
  }, []);

  const handleSend = (type, isAcrual) => {
    const sendingType = type || selectedType;
    setLoading(true);
    setAnchorEl(null);
    Swal.fire({
      title: `Seçilen Tebligatlar Gönderiliyor.`,
      text: "Lütfen Bekleyiniz...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    Swal.showLoading();
    if (isAcrual) {
      sendNotificationsToTaxpayer(selectedIds, sendingType)
        .then((resp) => {
          setLoading(false);
          Swal.fire({
            text: resp.data?.message,
            icon: "success",
            confirmButtonText: "Tamam",
            customClass: {
              htmlContainer: ".swal2-container",
            },
          });
          setReload(!reload);
        })
        .catch((err) => {
          setLoading(false);

          if (
            err?.response?.status === 401 &&
            err?.response?.data?.status === "W401"
          ) {
            setQR(err?.response?.data?.data);
            setMessage(err?.response?.data?.message);
            setQRModalOpen(true);
          } else {
            Swal.fire({
              text: err?.response?.data?.message || "Hata oluştu",
              icon: "warning",
              confirmButtonText: "Tamam",
              customClass: { htmlContainer: ".swal2-container" },
            });
          }
          setReload(!reload);
        });
    } else {
      sendDeclarationsToTaxpayer(selectedIds, sendingType)
        .then((resp) => {
          setLoading(false);
          Swal.fire({
            text: resp.data?.message,
            icon: "success",
            confirmButtonText: "Tamam",
            customClass: {
              htmlContainer: ".swal2-container",
            },
          });
          setReload(!reload);
        })
        .catch((err) => {
          setLoading(false);

          if (
            err?.response?.status === 401 &&
            err?.response?.data?.status === "W401"
          ) {
            setQR(err?.response?.data?.data);
            setMessage(err?.response?.data?.message);
            setQRModalOpen(true);
          } else {
            Swal.fire({
              text: err?.response?.data?.message || "Hata oluştu",
              icon: "warning",
              confirmButtonText: "Tamam",
              customClass: { htmlContainer: ".swal2-container" },
            });
          }
          setReload(!reload);
        });
    }
  };
  
  const search = () => {
    getNotificationList(
      id,
      currentPage,
      pageSize,
      sort,
      filters,
      startDate ? date.format(new Date(startDate), "YYYY-MM-DD") : null,
      endDate ? date.format(new Date(endDate), "YYYY-MM-DD") : null
    )
      .then((resp) => {
        setData(resp.data.data);
        setTotalPage(resp.data.totalPage);
        setTotalCount(resp.data.totalCount);
        // console.log(data, "data");
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };

  const handlePdfClick = async (rDriveID) => {
    setLoading(true);
    Swal.fire({
      title: "PDF Yükleniyor",
      text: "Lütfen Bekleyiniz...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading(); // Yükleme simgesini göster
      },
    });
    await getAccrualPdf(rDriveID)
      .then((resp) => {
        Swal.close();
        setLoading(false);
        setSelectedPdfData(resp.data.pdfBase64);
        setPdfModalOpen(true);
      })
      .catch((err) => {
        Swal.close();
        setLoading(false);
        Swal.fire({
          text: "PDF yüklenirken bir hata oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };
  const ColumnDefs = createColumnDefs(
    handlePdfClick,
    setOpenMailHistoryModal,
    setSelectedHistory
  );

  const onGridReady = useCallback((params) => {
    setRowData(data);
  }, []);

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid item md={12} sx={{ mt: 1 }}>
            <Box
              sx={{
                border: "1px solid #ccc", // Kenarlık rengi
                borderRadius: "8px", // Köşeleri yuvarlama
                padding: "16px", // İçerik dolgulama
                backgroundColor: "white", // Arka plan rengi
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Gölge efekti
              }}
            >
              <Grid container sx={{ marginTop: "0%", alignItems: "center" }}>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      gap: 1,
                      width: "90%",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700" }}>
                      {" "}
                      {taxPayerData?.title}
                    </Typography>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Grid sx={{ display: { lg: "flex", xl: "flex" } }}>
                    <Typography>
                      {taxPayerData?.tckn ? "TCKN : " : ""}
                    </Typography>
                    <Typography sx={{ fontWeight: "700" }}>
                      {" "}
                      {taxPayerData?.tckn}
                    </Typography>{" "}
                  </Grid>

                  <Grid
                    sx={{
                      display: taxPayerData?.tckn
                        ? {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "flex",
                            xl: "flex",
                          }
                        : { lg: "flex", xl: "flex" },
                    }}
                  >
                    {taxPayerData?.vkn && (
                      <>
                        <Typography>VKN : </Typography>
                        <Typography sx={{ fontWeight: "700" }}>
                          {" "}
                          {taxPayerData?.vkn}{" "}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>{" "}
                <Grid item xs={1.5}>
                  <Typography
                    sx={{
                      flexDirection: "row",
                      display: "flex",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    Durumu:{" "}
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: taxPayerData.is_deleted ? "red" : "green",
                      }}
                    >
                      {" "}
                      {taxPayerData.is_deleted ? "Pasif" : "Aktif"}
                    </Typography>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "",
                    }}
                    endIcon={<ManageAccountsIcon />}
                    variant="contained"
                    //  onClick={() => setDetailModalOpen(true) }
                    onClick={() => setDetailsModalOpen(true)}
                  >
                    Detay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            className="grid-area"
            sx={{
              mb: 1,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "space-between",
              }}
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid
                  item
                  xs={7}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="tr"
                  >
                    {/* DateRangePicker en solda */}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        height: "1vh",
                        alignItems: "center",
                      }}
                    >
                      <DateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        endDate={endDate}
                        isLimitedRangeForMonth={false}
                      />
                      <Button
                        sx={{
                          textTransform: "none",
                          alignItems: "center",
                          gap: 1,
                          marginLeft: 2,
                          marginRight: 2,
                          backgroundColor: "#00cfe8",
                          "&:hover": {
                            backgroundColor: darken(0.1, "#00cfe8"),
                            borderColor: "#d9534f",
                          },
                        }}
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => search()}
                      >
                        <SearchIcon color="white" />
                        Ara
                      </Button>
                      {/* <Button
                        sx={{
                          textTransform: "none",
                          alignItems: "center",
                          backgroundColor: "#6c757d",
                          "&:hover": {
                            backgroundColor: darken(0.1, "#6c757d"),
                            borderColor: "#d9534f",
                          },
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => setOpenDrawer(true)}
                      >
                        <FilterAltIcon color="white" />
                        Filtrele
                      </Button> */}
                    </Grid>

                    {/* Ara ve Filtrele butonları */}

                    {/* Seçilen Tahakkukları Gönder butonu en sağda */}
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonGroup>
                    <Tooltip
                      title={
                        selectedIds?.length === 0
                          ? "Lütfen tebligat seçiniz"
                          : taxPayerData?.mail?.length === 0 &&
                            taxPayerData?.number?.length === 0
                          ? "Mükellefin kayıtlı E-posta ve telefon numarası yok"
                          : ""
                      }
                      style={{ display: "flex" }}
                    >
                      <Button
                        sx={{
                          textTransform: "none",
                          color: lighten(0.1, "green"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.7, "green"),
                          "&:hover": {
                            backgroundColor: lighten(0.6, "green"),
                          },
                        }}
                        disabled={
                          selectedIds?.length === 0 ||
                          (taxPayerData?.mail?.length === 0 &&
                            taxPayerData?.number?.length === 0)
                        }
                        onClick={() => {
                          setSelectedType(1);
                          handleSend(1, true);
                        }}
                        variant="contained"
                        size="small"
                        startIcon={<SendIcon sx={{ marginRight: 1 }} />}
                      >
                        {" "}
                        Seçilen Tebligatları Gönder
                      </Button>
                    </Tooltip>
                    <Button
                      sx={{
                        textTransform: "none",
                        color: lighten(0.1, "green"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.7, "green"),
                        "&:hover": {
                          backgroundColor: lighten(0.6, "green"),
                        },
                      }}
                      disabled={selectedIds?.length === 0}
                      onClick={handleClickAcrual}
                      size="small"
                      variant="contained"
                    >
                      <ArrowDropDownIcon
                        sx={{
                          color: selectedIds?.length === 0 ? "gray" : "black",
                        }}
                      />
                    </Button>
                  </ButtonGroup>
                  <Popover
                    id={popoverIdAcrual}
                    open={openAcrual}
                    anchorEl={anchorElAcrual}
                    onClose={handleCloseAcrual}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          padding: "1%",
                          flexDirection: "column",
                          display: "flex",
                          gap: 5,
                          boxShadow: 0,
                          background: "transparent",
                          elevation: 0,
                          WebkitBoxShadow: "none",
                        },
                      },
                    }}
                    sx={{ marginTop: "-0.5%" }}
                  >
                    <Tooltip
                      title={
                        taxPayerData?.mail?.length === 0
                          ? "Kayıtlı E-posta adresi yok"
                          : ""
                      }
                    >
                      <Button
                        onClick={() => {
                          handleSend(2, true);
                          setSelectedType(2);
                        }}
                        disabled={taxPayerData?.mail?.length === 0}
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          color: lighten(0.2, "blue"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.4, "blue"),
                          "&:hover": {
                            backgroundColor: lighten(0.3, "blue"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        fullWidth
                      >
                        <img
                          src={gmail}
                          style={{
                            width: "20px",
                            marginRight: "7%",
                            filter:
                              taxPayerData?.mail?.length === 0
                                ? "grayscale(100%)"
                                : "none",
                          }}
                        />
                        E-postaya Gönder
                      </Button>{" "}
                    </Tooltip>
                    <Tooltip
                      title={
                        taxPayerData?.number?.length === 0
                          ? "Kayıtlı telefon numarası yok"
                          : ""
                      }
                    >
                      <Button
                        onClick={() => {
                          handleSend(3, true);
                          setSelectedType(3);
                        }}
                        fullWidth
                        disabled={taxPayerData?.number?.length === 0}
                        sx={{
                          textTransform: "none",
                          color: lighten(0.1, "green"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.7, "green"),
                          "&:hover": {
                            backgroundColor: lighten(0.6, "green"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        variant="contained"
                      >
                        <WhatsAppIcon sx={{ marginRight: 1 }} />
                        WhatsApp'a Gönder
                      </Button>
                    </Tooltip>
                    <Tooltip title="Hatalı olan tebligat PDF'lerini silmek için tıklayın">
                      <Button
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            title:
                              "Seçilen tebligatların PDF dosyaları silinecek.Devam etmek istiyor musunuz?",
                            denyButtonText: "Hayır, devam etme",
                            showDenyButton: true,
                            confirmButtonText: "Evet, devam et",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setLoading(true);
                              setAnchorEl(null);
                              markNotificationAsInvalid(selectedIds)
                                .then((resp) => {
                                  setReload(!reload);
                                  Swal.fire({
                                    icon: "success",
                                    title: resp.data.message,
                                    confirmButtonText: "Tamam",
                                  });
                                  setLoading(false);
                                })
                                .catch((err) => {
                                  Swal.fire({
                                    icon: "warning",
                                    title: err.response.data.message,
                                  });
                                  setLoading(false);
                                });
                            }
                          });
                        }}
                        fullWidth
                        disabled={selectedIds?.length === 0}
                        sx={{
                          textTransform: "none",
                          color: lighten(0.1, "red"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.4, "red"),
                          "&:hover": {
                            backgroundColor: lighten(0.3, "red"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        variant="contained"
                      >
                        <FolderDeleteIcon sx={{ marginRight: 1 }} />
                        Tebligat PDF'lerini hatalı olarak işaretle
                      </Button>
                    </Tooltip>
                  </Popover>

                  <Popover
                    id={popoverIdDeclaration}
                    open={openDeclaration}
                    anchorEl={anchorElDeclaration}
                    onClose={handleCloseDeclaration}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          padding: "1%",
                          flexDirection: "column",
                          display: "flex",
                          gap: 5,
                          boxShadow: 0,
                          background: "transparent",
                          elevation: 0,
                          WebkitBoxShadow: "none",
                        },
                      },
                    }}
                    sx={{ marginTop: "-0.5%" }}
                  >
                    <Tooltip
                      title={
                        taxPayerData?.mail?.length === 0
                          ? "Kayıtlı E-posta adresi yok"
                          : ""
                      }
                    >
                      <Button
                        onClick={() => {
                          handleSend(2, false);
                          setSelectedType(2);
                        }}
                        disabled={taxPayerData?.mail?.length === 0}
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          color: lighten(0.2, "blue"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.4, "blue"),
                          "&:hover": {
                            backgroundColor: lighten(0.3, "blue"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        fullWidth
                      >
                        <img
                          src={gmail}
                          style={{
                            width: "20px",
                            marginRight: "7%",
                            filter:
                              taxPayerData?.mail?.length === 0
                                ? "grayscale(100%)"
                                : "none",
                          }}
                        />
                        E-postaya Gönder
                      </Button>{" "}
                    </Tooltip>
                    <Tooltip
                      title={
                        taxPayerData?.number?.length === 0
                          ? "Kayıtlı telefon numarası yok"
                          : ""
                      }
                    >
                      <Button
                        onClick={() => {
                          handleSend(3, false);
                          setSelectedType(3);
                        }}
                        fullWidth
                        disabled={taxPayerData?.number?.length === 0}
                        sx={{
                          textTransform: "none",
                          color: lighten(0.1, "green"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.7, "green"),
                          "&:hover": {
                            backgroundColor: lighten(0.6, "green"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        variant="contained"
                      >
                        <WhatsAppIcon sx={{ marginRight: 1 }} />
                        WhatsApp'a Gönder
                      </Button>
                    </Tooltip>
                    <Tooltip title="Hatalı olan beyanname PDF'lerini silmek için tıklayın">
                      <Button
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            title:
                              "Seçilen tebligatların PDF dosyaları silinecek.Devam etmek istiyor musunuz?",
                            denyButtonText: "Hayır, devam etme",
                            showDenyButton: true,
                            confirmButtonText: "Evet, devam et",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setLoading(true);
                              setAnchorEl(null);
                              markNotificationAsInvalid(selectedIds)
                                .then((resp) => {
                                  setReload(!reload);
                                  Swal.fire({
                                    icon: "success",
                                    title: resp.data.message,
                                    confirmButtonText: "Tamam",
                                  });
                                  setLoading(false);
                                })
                                .catch((err) => {
                                  Swal.fire({
                                    icon: "warning",
                                    title: err.response.data.message,
                                  });
                                  setLoading(false);
                                });
                            }
                          });
                        }}
                        fullWidth
                        disabled={selectedIds?.length === 0}
                        sx={{
                          textTransform: "none",
                          color: lighten(0.1, "red"),
                          fontWeight: "700",
                          backgroundColor: lighten(0.4, "red"),
                          "&:hover": {
                            backgroundColor: lighten(0.3, "red"),
                          },
                          "&:disabled": {
                            background: lighten(0.3, "gray"),
                          },
                        }}
                        variant="contained"
                      >
                        <FolderDeleteIcon sx={{ marginRight: 1 }} />
                        Beyanname PDF'lerini hatalı olarak işaretle
                      </Button>
                    </Tooltip>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className="ag-theme-quartz"
            style={{
              flex: 1,
              minHeight: "65vh",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px", // İstediğiniz değeri buraya ekleyin
              overflow: "hidden", // Border-radius'in düzgün görünmesi için
            }}
          >
            <AgGridReact
              localeText={localeTextTr}
              rowSelection="multiple"
              rowData={data}
              columnDefs={ColumnDefs}
              enableRangeSelection={true}
              sideBar={gridSideBar}
              copyHeadersToClipboard={true}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "center",
              display: data?.length === 0 ? "none" : "flex",
              margin: "2% 0 2% 0",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPage}
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
            <TextField
              select
              value={pageSize}
              size="small"
              sx={{
                display: data && data?.length === 0 ? "none" : "block",
              }}
              onChange={(e) => {
                setCurrentPage(1);
                setPageSize(e.target.value);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={250}>250</MenuItem>
            </TextField>
            <Typography sx={{ position: "absolute", right: "1%" }}>
              Toplam Kayıt : {totalCount}{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "space-between",
              display: rowData?.length === 0 ? "none" : "flex",
              margin: "2% 0 2% 0",
              alignItems: "center",
            }}
          >
            <Dialog
              open={unDeclaratedTaxesModalOpen}
              onClose={() => setUnDeclaratedTaxesModalOpen(false)}
              maxWidth="lg"
            >
              <DialogContent>
                <UnDeclaratedTaxesModal
                  data={taxPayerData}
                  setReload={setReload}
                  reload={reload}
                />
              </DialogContent>
            </Dialog>

            <Dialog
              open={detailsModalOpen}
              onClose={() => !loading && setDetailsModalOpen(false)} // Conditionally close the Dialog
              maxWidth="lg"
              sx={{
                overflow: {
                  x: "hidden",
                  y: "hidden",
                  maxheight: "100vh",
                  minheight: "100vh",
                },
              }} // Scroll özelliğini none olarak ayarla
            >
              <DialogContent>
                <TaxPayerNotificationDetailsModal
                  taxPayerData={taxPayerData}
                  setReload={setReload}
                  reload={reload}
                />
              </DialogContent>{" "}
              <Dialog
                open={unDeclaratedTaxesModalOpen}
                onClose={() => setUnDeclaratedTaxesModalOpen(false)}
                maxWidth="lg"
              >
                <DialogContent>
                  <UnDeclaratedTaxesModal
                    data={taxPayerData}
                    setReload={setReload}
                    reload={reload}
                  />
                </DialogContent>
              </Dialog>
            </Dialog>
            <QRModal
              setQRModalOpen={setQRModalOpen}
              QRModalOpen={QRModalOpen}
              message={message}
              QR={QR}
              process={"send"}
            />
            <Dialog
              maxWidth="xl"
              open={isPdfModalOpen}
              onClose={() => setPdfModalOpen(false)}
            >
              <ShowPdfModal base64={selectedPdfData} />
            </Dialog>
            <MailHistoryModal
              data={selectedHistory}
              setModalOpen={setOpenMailHistoryModal}
              openModal={openMailHistoryModal}
            />
            <Drawer
              open={openDrawer}
              anchor="right"
              onClose={() => setOpenDrawer(false)}
            >
              <FilterDrawer
                page={"taxpayer"}
                filters={filters}
                setFilters={setFilters}
                handleFetchData={search}
              />
            </Drawer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TaxPayerNotificationPage;
