/** @format */
import axios from "axios";
import CheckToken from "../../../utils/checkToken/CheckToken";
export const getPasswordsFromAccountingProgram = async (data) => {
  const token = await CheckToken();
  if (!token) {
    // Redirect to login page
    window.location.href = "/login";
  }
  return await axios.post(
    `${process.env.REACT_APP_PROXY_API_URL}/rahat-musavir/get-dvd-credentials`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // return await axios.post(
  //   `${process.env.REACT_APP_API_URL}/tax-payer/get-passwords-from-accounting-program`,
  //   { data },
  //   {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   }
  // );
};
