import {
  Grid,
  TextField,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { getTaxPayerListDetails } from "../../api/taxPayer/getTaxPayerListDetails";
import { updateTaxPayersPassword } from "../../api/taxPayer/updateTaxPayersPassword";
import { showLoadingSwal } from "../../components/loadingSwal";
import withReactContent from "sweetalert2-react-content";
import { getPasswordsFromAccountingProgram } from "../../api/taxPayer/getPasswordsFromAccountingProgram";
const MySwal = withReactContent(Swal);

const AddTaxModal = ({ selectedTaxPayersIds, setAddTaxModalOpen }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTaxPayerListDetails(selectedTaxPayersIds)
      .then((resp) => {
        // console.log(resp.data);
        setUsers(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          text:
            err?.response?.data?.message ||
            "Mükkelef bilgileri alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
        setAddTaxModalOpen(false);
        setLoading(false);
      });
  }, [selectedTaxPayersIds]);

  const handleInputChange = (index, event) => {
    const newUsers = [...users];
    const newPassword = event.target.value;

    // ivd_credentials[0] her zaman var, sadece ivd_password güncellenecek
    if (Array.isArray(newUsers[index].ivd_credentials)) {
      newUsers[index].ivd_credentials[0] = {
        ...newUsers[index].ivd_credentials[0],
        ivd_password: newPassword,
      };
    } else {
      newUsers[index].ivd_credentials = [
        {
          ivd_password: newPassword,
        },
      ];
    }

    setUsers(newUsers);
  };
  const retryCount = useRef(0);
  const tryLucaLogin = async (result) => {
    const sendRequestWithCaptcha = (captchaValue) => {
      let data = {
        retryCount: retryCount.current,
        captchaOr2FACode: captchaValue || undefined,
        ...result.value,
      };
      getPasswordsFromAccountingProgram(data)
        .then((resp) => {
          if (resp?.data?.status === "success") {
            const updatedUsers = users.map((user) => {
              // Null kontrolleri
              if (!user.ivd_credentials || !user.vkn) {
                return user;
              }

              // Eşleşen şifreyi bul
              const matchingPassword = resp?.data?.data?.find(
                (p) =>
                  String(p.vkn) === String(user.vkn) ||
                  String(p.tckn) === String(user.tckn)
              );
              // Eşleşme yoksa orijinal kullanıcıyı döndür
              if (!matchingPassword) {
                return user;
              }
              // Yeni bir nesne oluştur ve şifreyi güncelle
              return {
                ...user,
                ivd_credentials: user.ivd_credentials.map((cred) => ({
                  ...cred,
                  ivd_password: matchingPassword.password,
                })),
              };
            });
            console.log(updatedUsers, "updatedUsers");
            setUsers(updatedUsers);
          }
          MySwal.fire({
            icon:
              resp?.data?.base64Image || resp?.data?.status==='needTo2FA'
                ? undefined
                : resp?.data?.status === "success"
                ? "success"
                : "warning",
            input:
              resp?.data?.status==='captcha' || resp?.data?.status==='needTo2FA'? "text" : undefined,
            inputAttributes: {
              autocapitalize: "off",
              autocomplete: "off",
            },
            html: `
              ${
                resp?.data?.base64Image
                  ? `<img src="data:image/jpeg;base64,${resp.data.base64Image}" alt="Resim" style="max-width: 100%; margin-bottom: 10px;" />`
                  : ""
              }
              <p>${resp?.data?.message || "Hata Oluştu"}</p>
            `,
            confirmButtonText:
              resp?.data?.base64Image || resp?.data?.status==='needTo2FA'
                ? "Gönder"
                : resp?.data?.message?.includes(
                    "Şifrenizin süresi dolmuştur."
                  ) || resp?.data?.status === "success"
                ? "Tamam"
                : "Tekrar Dene",
            focusConfirm: true,
            showDenyButton:
              !resp?.data?.message?.includes("Şifrenizin süresi dolmuştur.") &&
              resp?.data?.status !== "success",
            denyButtonText:
              resp?.data?.status === "success"
                ? undefined
                : "Vazgeç",

            preConfirm: (captcha) => {
              if (!captcha) {
                Swal.showValidationMessage("Lütfen bir metin girin");
              }
              return { captcha };
            },
          }).then((result) => {

            if (resp?.data?.status === "success") {
              return;
            }
            const captcha = result?.value?.captcha;
            if (captcha && result.isConfirmed) {
              retryCount.current += 1;
              sendRequestWithCaptcha(captcha); // Captcha ile aynı isteği tekrar gönder
            }
          });
        })
        .catch((error) => {
          console.error(error, "Hata Oluştu");
          if (error?.code === "ERR_NETWORK") {
            Swal.fire({
              title: "Rahat Proxy'e Bağlanılamadı.",
              text: "Rahat Proxy kapalı ise lütfen başlatınız. Kurulu değilse butona tıklayarak indirip, yükleyebilirsiniz.",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "Rahat Proxy İndir",
              cancelButtonText: "İptal",
              customClass: { htmlContainer: ".swal2-container" },
              preConfirm: () => {
                try {
                  window.location.href = "/downloads/RahatProxy.rar";
                } catch (error) {
                  Swal.fire({
                    icon: "error",
                    title: "Dosya İndirilemedi!",
                    text: "Dosya indirme işlemi sırasında bir hata oluştu.",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          } else {
            Swal.fire({
              text:
                error?.response?.data?.message ||
                "Şifreler güncellenirken hata oluştu.",
              icon: "error",
              confirmButtonText: "Tamam",
              customClass: {
                container: ".swal-overlay",
              },
            });
          }
        });
    };

    // İlk isteği captcha olmadan yap
    sendRequestWithCaptcha(null);
  };

  const handleGetPasswordFromLuca = () => {
    MySwal.fire({
      title: "Luca bilgilerinizi giriniz.",
      html: (
        <div>
          <TextField
            id="customerCode"
            label="Üye Numarası"
            size="small"
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <TextField
            id="username"
            label="Kullanıcı Adı"
            size="small"
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <TextField
            id="password"
            label="Şifre"
            type="password"
            size="small"
            fullWidth
          />
          <Typography
            variant="caption"
            gutterBottom
            sx={{ display: "block", marginTop: 2 }}
          >
            *Luca bilgileriniz <b>kaydedilmemektedir</b>.
          </Typography>
        </div>
      ),
      showCancelButton: true,
      confirmButtonText: "Gönder",
      cancelButtonText: "İptal",
      preConfirm: () => {
        const customerCode = document.getElementById("customerCode").value;
        const username = document.getElementById("username").value;
        const password = document.getElementById("password").value;

        if (!customerCode || !username || !password) {
          Swal.showValidationMessage("Lütfen tüm alanları doldurun");
        }

        return { customerCode, username, password };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(result.value, "result.value");
        await tryLucaLogin(result);
      }
    });
  };

  const handleSave = () => {
    // showLoadingSwal()
    let credentialsList = [];
    users.forEach((user) => {
      if (
        Array.isArray(user.ivd_credentials) && // user.ivd_credentials bir dizi mi?
        user.ivd_credentials.length > 0 // dizi boş değil mi?
      ) {
        credentialsList.push({
          vkn: user.vkn,
          newPassword: user.ivd_credentials[0].ivd_password || null,
        });
      }
    });

    updateTaxPayersPassword(credentialsList)
      .then((resp) => {
        Swal.fire({
          text: `${resp.data.message}`,
          icon: "success",
          confirmButtonText: "Tamam",
        });
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          text:
            err?.response?.data?.message ||
            "Mükkelef bilgileri kaydedilirken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
        setAddTaxModalOpen(false);
        setLoading(false);
      });
  };

  const toggleShowPassword = (index) => {
    const newUsers = [...users];
    newUsers[index].showPassword = !newUsers[index].showPassword;
    setUsers(newUsers);
  };

  const [showStatus, setShowStatus] = useState(false);

  const turnShowAllPasswords = (show) => {
    const newUsers = users.map((user) => ({
      ...user,
      showPassword: !show,
    }));
    setUsers(newUsers);
  };

  return (
    <>
      {loading && (
        <Grid
          container
          spacing={2}
          sx={{
            height: "75vh",
            width: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <CircularProgress size={40} thickness={4} />
            <Typography variant="body1" sx={{ mt: 2, color: "text.secondary" }}>
              Yükleniyor...
            </Typography>
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container sx={{ alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h5" gutterBottom>
              Mükellef Şifre Güncelleme
            </Typography>

            <Grid item sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleGetPasswordFromLuca}
                style={{ marginBottom: "16px", textTransform: "none" }}
              >
                Şifreleri muhasebe programından getir
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  turnShowAllPasswords(showStatus);
                  setShowStatus(!showStatus);
                }}
                style={{ marginBottom: "16px", textTransform: "none" }}
              >
                {users &&
                users.length > 0 &&
                users?.some((user) => user.showPassword)
                  ? "Tüm Şifreleri Gizle"
                  : "Tüm Şifreleri Göster"}
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              border: "1px inset black",
              pl: 1,
              pr: 1,
              mb: 2,
              overflowY: "auto",
              maxHeight: "75vh",
            }}
          >
            {users &&
              users.length > 0 &&
              users?.map((user, index) => (
                <React.Fragment key={user.id}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography variant="body1">{user?.title}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type={user.showPassword ? "text" : "password"}
                        label="Şifre"
                        variant="outlined"
                        size="small"
                        value={
                          Array.isArray(user.ivd_credentials)
                            ? user.ivd_credentials[0]?.ivd_password ?? ""
                            : ""
                        }
                        onChange={(event) => handleInputChange(index, event)}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() => toggleShowPassword(index)}
                              style={{ marginLeft: "8px" }}
                            >
                              {user.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </Button>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {index < users?.length - 1 && (
                    <Grid item xs={12}>
                      <Divider sx={{ backgroundColor: "#060647" }} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              onClick={handleSave}
            >
              Kaydet
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AddTaxModal;
