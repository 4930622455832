import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { darken, lighten } from "polished";
import RemoveTaxModal from "./RemoveTaxModal.js";
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import LoadingOverlay from "react-loading-overlay";
import { handleAutoSendAccrualsAndDeclarations } from "../../api/taxPayer/handleAutoSendAccrualsAndDeclarations/index.js";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";
import { FileInfo } from "tabler-icons-react";
import createColumnDefs from "./columnDefs.js";
import localStorage from "local-storage";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddTaxModal from "./AddTaxModal.js";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { getTaxPayersAsExcel } from "../../api/taxPayer/getTaxPayersAsExcel/index.js";
import { getTaxPayerList } from "../../api/taxPayer/getTaxPayerList/index.js";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

import {
  Grid,
  Typography,
  Pagination,
  TextField,
  MenuItem,
  DialogContent,
  ButtonGroup,
  Popover,
  Button,
  Drawer,
  Dialog,
  Tooltip,
  Menu,
} from "@mui/material";
import SyncModal from "./SyncModal.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import localeTextTr from "../../locale.tr.js";
import Swal from "sweetalert2";
import MemoryIcon from "@mui/icons-material/Memory";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ReportModal from "./ReportModal.js";
import FilterDrawer from "../../components/FilterDrawer/index.js";
import moment from "moment";
import { cleanupLocalStorage } from "../../utils/localStorage/cleanupLocalStorage.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";

function TaxPayersPage() {
  const [rowData, setRowData] = useState([]);
  const [refreshGrid, setRefreshGrid] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchedValue, setSearchedValue] = useState(null);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [anchorTypeSub, setAnchorTypeSub] = useState(null);
  const [open, setOpen] = useState(false);

  const getPreviousMonthAndCurrentYear = () => {
    const currentDate = moment();
    const previousMonth = currentDate.subtract(1, "month").format("MM"); // Bir önceki ay (full ad olarak)
    const currentYear = currentDate.year(); // Mevcut yıl
    return { previousMonth, currentYear: currentYear.toString() };
  };

  const { previousMonth, currentYear } = getPreviousMonthAndCurrentYear();

  const [filters, setFilters] = useState({
    declarationStatus: null,
    declarationOperator: null,
    isDeclarationTypes: [],
    periodMonth: previousMonth || null,
    periodYear: currentYear || null,
    startDate:
      moment().subtract(1, "month").startOf("month").format("DD/MM/YYYY") ||
      null,
    endDate:
      moment().subtract(1, "month").endOf("month").format("DD/MM/YYYY") || null,
    rangeIsPeriod: true,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorType, setAnchorType] = useState(null);
  const [selectedTaxPayersData, setSelectedTaxPayersData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [addTaxModalOpen, setAddTaxModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState({ name: null, sort: null });
  const [anchorElProcess, setAnchorElProcess] = useState(null);
  const [selectedTaxPayersTitle, setSelectedTaxPayersTitle] = useState([]);
  const [removeTaxModalOpen, setRemoveTaxModalOpen] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [anchorElSub, setAnchorElSub] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");

    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }
    localStorage.get("scrollPosition");

    search();
    cleanupLocalStorage();
    window.addEventListener("beforeunload", cleanupLocalStorage);
    return () => {
      window.removeEventListener("beforeunload", cleanupLocalStorage);
    };
  }, []);

  useEffect(() => {
    const lastPage = localStorage.get("page");
    const lastPageSize = localStorage.get("pageSize");
    const sideBarOpen = localStorage.get("sidebar");

    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    if (lastPage) {
      setCurrentPage(lastPage);
    }
    if (lastPageSize) {
      setPageSize(lastPageSize);
    }
    localStorage.get("scrollPosition");
  }, [currentPage, pageSize]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickProcess = (event) => {
    setAnchorElProcess(event.currentTarget);
  };
  const handleCloseProcess = () => {
    setAnchorElProcess(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();

    const selectedIds = selectedNodes.map((node) => node.data.id);
    setSelectedIds(selectedIds);

    const selectedTaxPayersTitle = selectedNodes.map((node) => node.data.title);
    setSelectedTaxPayersTitle(selectedTaxPayersTitle);
    //console.log(selectedTaxPayers,selectedIds);

    const selectedTaxPayersData = selectedIds.map((id, index) => ({
      id: id,
      name: selectedTaxPayersTitle[index],
    }));
    setSelectedTaxPayersData(selectedTaxPayersData);
    // console.log("Seçilen Mükellefler:", selectedTaxPayersData);
  };

  const popoverId = open ? "simple-popover" : undefined;
  const openSub = Boolean(anchorElSub);
  const popoverSubId = openSub ? "simple-popover" : undefined;

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: "country",
          minWidth: 900,
        },
      ],
    };
  }, []);
  const handleClickAddTax = (event) => {
    if (selectedTaxPayersTitle.length === 0) {
      Swal.fire({
        text: "Toplu Vergi Eklemek İçin Mükellef Seçiniz",
        icon: "info",
        confirmButtonText: "TAMAM",
      });
    } else {
      setAddTaxModalOpen(true);
    }
    handleCloseProcess();
  };

  const handleClickRemoveTax = (event) => {
    if (selectedTaxPayersTitle.length === 0) {
      Swal.fire({
        text: "Toplu Vergi Silmek İçin Mükellef Seçiniz",
        icon: "info",
        confirmButtonText: "Tamam",
      });
    } else {
      setRemoveTaxModalOpen(true);
    }
    handleCloseProcess();
  };

  const handleTaxPayersExcelDownload = async () => {
    try {
      await getTaxPayersAsExcel()
        .then((resp) => {
          Swal.fire({
            text: resp.data,
            icon: "success",
            confirmButtonText: "Tamam",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message || "Hata oluştu",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        });
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const defaultColDef = useMemo(
    () => ({
      minWidth: 100,
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );
  function clearLocalStorage() {
    localStorage.clear();
  }
  useEffect(() => {
    let timer;
    setTimeout(clearLocalStorage, 10 * 60 * 1000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [pageSize, currentPage, sort]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const search = (text, searchCurrentPage, searchPageSize) => {
    setLoading(true);

    getTaxPayerList(
      searchCurrentPage ? searchCurrentPage : currentPage,
      searchPageSize ? searchPageSize : pageSize,
      text || searchedValue,
      sort,
      filters
    )
      .then((resp) => {
        setRowData(resp.data.data);
        setTotalPage(resp.data.totalPage);
        setTotalCount(resp.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          text:
            err.response.data.message || "Mükkelefler alınırken hata oluştu.",
          icon: "error",
          confirmButtonText: "Tamam",
          customClass: {
            container: ".swal-overlay",
          },
        });
      });
  };

  const handleClose = () => setOpen(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [columnDefs, setColumnDefs] = useState(
    createColumnDefs(navigate, setSelectedIds)
  );

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        defaultExpanded: false,
        // sideBar={gridSideBar},
        // rowSelection: "multiple",
        // suppressRowClickSelection: true,
        enableRangeSelection: true,
        // paginationAutoPageSize: true,
        columnDefs: [{ field: "description" }],
        defaultColDef: {
          flex: 3,
          maxWidth: 300,
          resizable: true,
          floatingFilter: true,
          suppressMenu: false, // Allow menu in detail grid columns
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.subDetails);
      },
    };
  }, []);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api); // gridApi'yi burada ayarlayın
      setRowData(rowData); // mevcut satır verilerini ayarlayın
    },
    [rowData]
  );

  const resetSelection = () => {
    gridApi.deselectAll(); // Tüm seçili satırları sıfırla
    setSelectedIds([]); // Seçili ID'leri temizle
    setSelectedTaxPayersTitle([]); // Seçili mükellef başlıklarını temizle
    setSelectedTaxPayersData([]); // Seçili mükellef verilerini temizle
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease", // Düzeltilmiş transition
        }}
      >
        <Sidebar status={isOpen} toggleSidebar={toggleSidebar} />
      </Grid>

      <Grid
        item
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            className="grid-area"
            sx={{
              mb: 1,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "space-between",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  sx={{ backgroundColor: "#ffffff" }}
                  variant="standard"
                  onBlur={(e) => {
                    setSearchedValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                      search(e.target.value);
                    }
                  }}
                  InputProps={{
                    // disableUnderline: true, // Çizgiyi kaldırmak için
                    placeholder: "Ara...", // Placeholder metni
                  }}
                />
              </Grid>{" "}
              <Button
                sx={{
                  textTransform: "none",
                  alignItems: "center",
                  gap: 1,
                  marginLeft: "1vw",

                  backgroundColor: "#00cfe8",
                  "&:hover": {
                    backgroundColor: darken(0.1, "#00cfe8"),
                    borderColor: "#d9534f",
                  },
                }}
                variant="contained"
                type="submit"
                onClick={() => {
                  search();
                }}
              >
                <SearchIcon color="white" />
                Ara
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  alignItems: "center",
                  marginLeft: "1vw",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: darken(0.1, "#6c757d"),
                    borderColor: "#d9534f",
                  },
                }}
                variant="contained"
                onClick={() => setOpenDrawer(true)}
              >
                <FilterAltIcon color="white" />
                Filtrele
              </Button>
              <ButtonGroup sx={{ marginLeft: "auto" }}>
                <Button
                  sx={{
                    width: "120px",
                    marginRight: "30px",
                    textTransform: "none",
                    backgroundColor: "Teal",
                    "&:hover": {
                      backgroundColor: darken(0.1, "teal"),
                      borderColor: "teal",
                    },
                    fontWeight: "500",
                  }}
                  variant="contained"
                  onClick={handleClickProcess}
                >
                  <MemoryIcon></MemoryIcon>
                  <span>&nbsp;&nbsp;</span>
                  İşlemler
                </Button>
                {
                  <Menu
                    anchorEl={anchorElProcess}
                    open={Boolean(anchorElProcess)}
                    onClose={handleCloseProcess}
                    sx={{
                      width: "17%",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <MenuItem onClick={handleClickAddTax}>
                      Toplu Vergi Ekleme
                    </MenuItem>
                    <MenuItem onClick={handleClickRemoveTax}>
                      Toplu Vergi Silme
                    </MenuItem>
                    {/* {unsubmittedData?.length !== 0 &&
                                unsubmittedData !== null &&
                                unsubmittedData !== undefined && (
                                    <MenuItem onClick={unsubmittedAccruals}>
                                        Gönderilmeyen Tahakkuklar
                                    </MenuItem>
                                )} */}
                    {/* <MenuItem onClick={handleCloseProcess}>Item 3</MenuItem> */}
                  </Menu>
                }

                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: darken(0.1, "green"),
                      borderColor: "green",
                    },
                    fontWeight: "500",
                  }}
                  variant="contained"
                  startIcon={<Icon path={mdiFileExcel} size={0.9} />}
                  onClick={() => setReportModalOpen(true)}
                >
                  Rapor indir
                </Button>
                <Drawer
                  open={openDrawer}
                  anchor="right"
                  onClose={() => setOpenDrawer(false)}
                >
                  <FilterDrawer
                    page={"taxpayers"}
                    filters={filters}
                    setFilters={setFilters}
                    handleFetchData={search}
                  />
                </Drawer>
                <Button
                  sx={{
                    textTransform: "none",
                    marginLeft: "auto",
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: darken(0.1, "green"),
                      borderColor: "green",
                    },
                    fontWeight: "500",
                  }}
                  onClick={(event) => {
                    handleClick(event);
                    setOpen(true);
                    setAnchorType(1);
                  }}
                  size="small"
                  variant="contained"
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <ButtonGroup sx={{ marginLeft: "2%" }}>
                <Tooltip title="Mükellef ve beyannameleri güncellemek için tıklayın">
                  <Button
                    sx={{
                      textTransform: "none",
                      marginLeft: "auto",
                      backgroundColor: "orange",
                      "&:hover": {
                        backgroundColor: darken(0.4, "orange"),
                        borderColor: "orange",
                      },
                      fontWeight: "500",
                    }}
                    variant="contained"
                    startIcon={<CloudSyncIcon />}
                    onClick={() => setSyncModalOpen(true)}
                  >
                    Senkronize et
                  </Button>
                </Tooltip>
                <Button
                  sx={{
                    textTransform: "none",
                    marginLeft: "auto",
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: darken(0.1, "orange"),
                      borderColor: "orange",
                    },
                    fontWeight: "500",
                  }}
                  onClick={(event) => {
                    handleClick(event);
                    setOpen(true);
                    setAnchorType(2);
                  }}
                  /////////////////  BURASIDA AŞAĞI OKU
                  size="small"
                  variant="contained"
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid
            className="ag-theme-quartz"
            style={{
              flex: 1,
              minHeight: "70vh",
              maxHeight: "70vh",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "10px", // İstediğiniz değeri buraya ekleyin
              overflow: "hidden", // Border-radius'in düzgün görünmesi için
            }}
          >
            <AgGridReact
              localeText={localeTextTr}
              rowSelection="multiple"
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              loading={loading}
              enableRangeSelection={true}
              sideBar={gridSideBar}
              getRowStyle={(params) => {
                if (params.data.is_deleted === true) {
                  return { background: lighten(0.4, "red") };
                }
              }}
              copyHeadersToClipboard={true}
              autoSizeStrategy={autoSizeStrategy}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={defaultColDef}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              justifyContent: "space-between",
              display: rowData?.length === 0 ? "none" : "flex",

              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                display: rowData.length === 0 ? "none" : "flex",
                margin: "2% 0 2% 0",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Pagination
                count={totalPage}
                showFirstButton
                showLastButton
                page={currentPage}
                sx={{
                  display: loading ? "none" : "block",
                }}
                onChange={(event, value) => {
                  search(undefined, value, pageSize);
                  setCurrentPage(value);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  localStorage.set("page", value);
                }}
              />
              <TextField
                select
                value={pageSize}
                size="small"
                sx={{
                  display: rowData.length === 0 || loading ? "none" : "block",
                }}
                onChange={(e) => {
                  search(undefined, 1, e.target.value);
                  setCurrentPage(1);
                  localStorage.set("page", 1);
                  localStorage.set("pageSize", e.target.value);
                  setPageSize(e.target.value);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
              <Typography
                sx={{
                  position: "absolute",
                  right: "3%",
                  display: !loading ? "block" : "none",
                }}
              >
                Toplam Kayıt : {totalCount}{" "}
              </Typography>
            </Grid>
            <Dialog
              open={syncModalOpen}
              onClose={() => loading && setSyncModalOpen(false)} // Conditionally close the Dialog
              maxWidth="md"
            >
              <LoadingOverlay
                active={loading}
                spinner
                text="Lütfen bekleyiniz..."
              >
                <DialogContent>
                  <SyncModal setSyncModalOpen={setSyncModalOpen} />
                </DialogContent>{" "}
              </LoadingOverlay>
            </Dialog>
            {/* addtax */}
            <Dialog
              open={addTaxModalOpen}
              onClose={() => !loading && setAddTaxModalOpen(false)} // Conditionally close the Dialog
              maxWidth="xl"
              PaperProps={{
                sx: {
                  overflow: "hidden",
                },
              }}
            >
              <LoadingOverlay
                active={loading}
                spinner
                text="Lütfen bekleyiniz..."
              >
                <DialogContent>
                  <AddTaxModal
                    setAddTaxModalOpen={setAddTaxModalOpen}
                    selectedTaxPayersData={selectedTaxPayersData}
                    setSelectedTaxPayersData={setSelectedTaxPayersData}
                    search={search}
                    // refreshGrid={refreshGrid}
                  />
                </DialogContent>{" "}
              </LoadingOverlay>
            </Dialog>
            {/* remover tax */}
            <Dialog
              open={removeTaxModalOpen}
              onClose={() => loading && setRemoveTaxModalOpen(false)} // Conditionally close the Dialog
              maxWidth="xl"
            >
              <LoadingOverlay
                active={loading}
                spinner
                text="Lütfen bekleyiniz..."
              >
                <DialogContent>
                  <RemoveTaxModal
                    setRemoveTaxModalOpen={setRemoveTaxModalOpen}
                    selectedTaxPayersData={selectedTaxPayersData}
                    setSelectedTaxPayersData={setSelectedTaxPayersData}
                    search={search}
                  />
                </DialogContent>{" "}
              </LoadingOverlay>
            </Dialog>
            <Dialog
              open={reportModalOpen}
              onClose={() => !loading && setReportModalOpen(false)} // Conditionally close the Dialog
              maxWidth="lg"
              sx={{ overflow: { x: "hidden", y: "hidden" } }} // Scroll özelliğini none olarak ayarla
            >
              <LoadingOverlay
                active={loading}
                spinner
                text="Lütfen bekleyiniz..."
              >
                <DialogContent>
                  <ReportModal setReportModalOpen={setReportModalOpen} />
                </DialogContent>{" "}
              </LoadingOverlay>
            </Dialog>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              slotProps={{
                paper: {
                  style: {
                    padding: "1%",
                    flexDirection: "column",
                    display: "flex",
                    gap: 5,
                    boxShadow: 0,
                    background: "transparent",
                    elevation: 0,
                    WebkitBoxShadow: "none",
                  },
                },
              }}
              sx={{ marginTop: "-0.5%" }}
            >
              {anchorType !== 1 ? (
                <>
                  {" "}
                  {/* 1. buton tahakkuk kapat */}
                  <ButtonGroup>
                    <Button
                      disabled={selectedIds?.length === 0}
                      sx={{
                        textTransform: "none",
                        color: lighten(0.2, "red"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.4, "red"),
                        "&:hover": {
                          backgroundColor: lighten(0.3, "red"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={(event) => {
                        setAnchorElSub(event.currentTarget);
                        setOpen(false);
                        setAnchorTypeSub(1);
                      }} //////////////7777 bu e posta otam. kapatma
                      size="small"
                      variant="contained"
                    >
                      <ArrowLeftIcon />
                    </Button>
                    <Button
                      disabled={selectedIds?.length === 0}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: darken(0.3, "red"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.4, "red"),
                        "&:hover": {
                          backgroundColor: lighten(0.3, "red"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={() => {
                        setAnchorElSub(null);
                        setOpen(false);
                        setAnchorEl(null);

                        Swal.fire({
                          text: "Seçilen mükelleflerin otomatik tahakkuk göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                          icon: "question",
                          showDenyButton: true,
                          denyButtonText: "Vazgeç",
                          confirmButtonText: "Devam Et",
                        }).then((resp) => {
                          if (resp.isConfirmed) {
                            refreshGrid?.deselectAll();

                            handleAutoSendAccrualsAndDeclarations(
                              selectedIds,
                              0,
                              false
                            )
                              .then((resp) => {
                                Swal.fire({
                                  text: resp.data.message,
                                  icon: "success",
                                  confirmButtonText: "Tamam",
                                });
                                resetSelection();
                              })
                              .catch((err) => {
                                Swal.fire({
                                  title:
                                    err.response.data.message || "Hata oluştu",
                                  icon: "error",
                                  confirmButtonText: "Tamam",
                                });
                              });
                          }
                        });
                      }}
                      fullWidth
                    >
                      Otomatik Tahakkuk Göndermeyi Kapat
                    </Button>{" "}
                  </ButtonGroup>
                  {/* 2. buton beyanname kapat */}
                  <ButtonGroup>
                    <Button
                      disabled={selectedIds?.length === 0}
                      sx={{
                        textTransform: "none",
                        color: lighten(0.2, "red"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.4, "red"),
                        "&:hover": {
                          backgroundColor: lighten(0.3, "red"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={(event) => {
                        setAnchorElSub(event.currentTarget);
                        setOpen(false);
                        setAnchorTypeSub(2);
                      }} ////////////////////////buda otomaitk whatsap eposta açma
                      size="small"
                      variant="contained"
                    >
                      <ArrowLeftIcon />
                    </Button>
                    <Button
                      disabled={selectedIds?.length === 0}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: darken(0.3, "red"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.4, "red"),
                        "&:hover": {
                          backgroundColor: lighten(0.3, "red"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={() => {
                        setAnchorElSub(null);
                        setOpen(false);
                        setAnchorEl(null);
                        Swal.fire({
                          text: "Seçilen mükelleflerin otomatik beyanname göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                          icon: "question",
                          showDenyButton: true,
                          denyButtonText: "Vazgeç",
                          confirmButtonText: "Devam Et",
                        }).then((resp) => {
                          if (resp.isConfirmed) {
                            refreshGrid?.deselectAll();

                            handleAutoSendAccrualsAndDeclarations(
                              selectedIds,
                              1,
                              false
                            )
                              .then((resp) => {
                                Swal.fire({
                                  text: resp.data.message,
                                  icon: "success",
                                  confirmButtonText: "Tamam",
                                });
                                setSelectedIds([]);
                                resetSelection();
                              })
                              .catch((err) => {
                                Swal.fire({
                                  title:
                                    err.response.data.message || "Hata oluştu",
                                  icon: "error",
                                  confirmButtonText: "Tamam",
                                });
                              });
                          }
                        });
                      }}
                      fullWidth
                    >
                      Otomatik Beyanname Göndermeyi Kapat
                    </Button>{" "}
                  </ButtonGroup>
                  {/* 3. buton tahakkuk aç */}
                  <ButtonGroup>
                    <Button
                      disabled={selectedIds?.length === 0}
                      sx={{
                        textTransform: "none",
                        color: lighten(0.0, "green"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.6, "green"),
                        "&:hover": {
                          backgroundColor: lighten(0.5, "green"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={(event) => {
                        setAnchorElSub(event.currentTarget);
                        setOpen(false);
                        setAnchorTypeSub(3);
                      }} ////////////////////////buda otomaitk whatsap eposta açma
                      size="small"
                      variant="contained"
                    >
                      <ArrowLeftIcon />
                    </Button>
                    <Button
                      disabled={selectedIds?.length === 0}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: darken(0.1, "green"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.65, "green"),
                        "&:hover": {
                          backgroundColor: lighten(0.5, "green"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={() => {
                        setAnchorElSub(null);
                        setOpen(false);
                        setAnchorEl(null);
                        Swal.fire({
                          text: "Seçilen mükelleflerin otomatik tahakkuk göndermesi açılacak.Devam etmek istiyor musunuz?",
                          icon: "question",
                          showDenyButton: true,
                          denyButtonText: "Vazgeç",
                          confirmButtonText: "Devam Et",
                        }).then((resp) => {
                          if (resp.isConfirmed) {
                            refreshGrid?.deselectAll();

                            handleAutoSendAccrualsAndDeclarations(
                              selectedIds,
                              2,
                              true
                            )
                              .then((resp) => {
                                Swal.fire({
                                  text: resp.data.message,
                                  icon: "success",
                                  confirmButtonText: "Tamam",
                                });
                                setSelectedIds([]);
                                resetSelection();
                              })
                              .catch((err) => {
                                Swal.fire({
                                  title:
                                    err.response.data.message || "Hata oluştu",
                                  icon: "error",
                                  confirmButtonText: "Tamam",
                                });
                              });
                          }
                        });
                      }}
                      fullWidth
                    >
                      Otomatik Tahakkuk Göndermeyi Aç
                    </Button>{" "}
                  </ButtonGroup>
                  {/* 4. buton beyanname aç */}
                  <ButtonGroup>
                    <Button
                      disabled={selectedIds?.length === 0}
                      sx={{
                        textTransform: "none",
                        color: lighten(0.0, "green"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.6, "green"),
                        "&:hover": {
                          backgroundColor: lighten(0.5, "green"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={(event) => {
                        setAnchorElSub(event.currentTarget);
                        setOpen(false);
                        setAnchorTypeSub(4);
                      }} ////////////////////////buda otomaitk whatsap eposta açma
                      size="small"
                      variant="contained"
                    >
                      <ArrowLeftIcon />
                    </Button>
                    <Button
                      disabled={selectedIds?.length === 0}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: darken(0.1, "green"),
                        fontWeight: "700",
                        backgroundColor: lighten(0.65, "green"),
                        "&:hover": {
                          backgroundColor: lighten(0.5, "green"),
                        },
                        "&:disabled": {
                          background: lighten(0.3, "gray"),
                        },
                      }}
                      onClick={() => {
                        setAnchorElSub(null);
                        setOpen(false);
                        setAnchorEl(null);
                        Swal.fire({
                          text: "Seçilen mükelleflerin otomatik beyanname göndermesi açılacak.Devam etmek istiyor musunuz?",
                          icon: "question",
                          showDenyButton: true,
                          denyButtonText: "Vazgeç",
                          confirmButtonText: "Devam Et",
                        }).then((resp) => {
                          if (resp.isConfirmed) {
                            refreshGrid?.deselectAll();

                            handleAutoSendAccrualsAndDeclarations(
                              selectedIds,
                              3,
                              true
                            )
                              .then((resp) => {
                                Swal.fire({
                                  text: resp.data.message,
                                  icon: "success",
                                  confirmButtonText: "Tamam",
                                });
                                setSelectedIds([]);
                                resetSelection();
                              })
                              .catch((err) => {
                                Swal.fire({
                                  title:
                                    err.response.data.message || "Hata oluştu",
                                  icon: "error",
                                  confirmButtonText: "Tamam",
                                });
                              });
                          }
                        });
                      }}
                      fullWidth
                    >
                      Otomatik Beyanname Göndermeyi Aç
                    </Button>{" "}
                  </ButtonGroup>
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      textTransform: "none",
                      backgroundColor: "green",
                      "&:hover": {
                        backgroundColor: darken(0.1, "green"),
                        borderColor: "green",
                      },
                      fontWeight: "700",
                    }}
                    variant="contained"
                    startIcon={<Icon path={mdiFileExcel} size={0.9} />}
                    onClick={handleTaxPayersExcelDownload}
                  >
                    Mükellef Listesini İndir
                  </Button>
                </>
              )}
            </Popover>

            {/* 2. sol ok bundan sonra */}
            <Popover
              id={popoverSubId}
              open={openSub}
              anchorEl={anchorElSub}
              onClose={() => setAnchorElSub(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              slotProps={{
                paper: {
                  style: {
                    padding: "1%",
                    flexDirection: "column",
                    display: "flex",
                    gap: 5,
                    boxShadow: 0,
                    background: "transparent",
                    elevation: 0,
                    WebkitBoxShadow: "none",
                  },
                },
              }}
              sx={{ marginTop: "-0.5%" }}
            >
              {anchorTypeSub == 1 ? (
                <>
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: lighten(0.2, "red"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.4, "red"),
                      "&:hover": {
                        backgroundColor: lighten(0.3, "red"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin E-posta otomatik tahakkuk göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            4,
                            false
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              resetSelection();
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    E-posta Tahakkuk Göndermeyi Kapat
                  </Button>{" "}
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: lighten(0.2, "red"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.4, "red"),
                      "&:hover": {
                        backgroundColor: lighten(0.3, "red"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin Whatsapp otomatik tahakkuk göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            5,
                            false
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              resetSelection();
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    Whatsapp Tahakkuk Göndermeyi Kapat
                  </Button>{" "}
                </>
              ) : anchorTypeSub == 2 ? (
                <>
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.3, "red"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.4, "red"),
                      "&:hover": {
                        backgroundColor: lighten(0.3, "red"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin E-posta otomatik beyanname göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            6,
                            false
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                              resetSelection();
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    E-posta Beyanname Göndermeyi Kapat
                  </Button>{" "}
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.3, "red"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.4, "red"),
                      "&:hover": {
                        backgroundColor: lighten(0.3, "red"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin Whatsapp otomatik beyanname göndermesi kapatılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            7,
                            false
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                              resetSelection();
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    Whatsapp Beyanname Göndermeyi Kapat
                  </Button>{" "}
                </>
              ) : anchorTypeSub == 3 ? (
                <>
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.1, "green"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.65, "green"),
                      "&:hover": {
                        backgroundColor: lighten(0.5, "green"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin E-posta otomatik tahakkuk göndermesi açılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            8,
                            true
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    E-posta Tahakkuk Göndermeyi Aç
                  </Button>{" "}
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.1, "green"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.65, "green"),
                      "&:hover": {
                        backgroundColor: lighten(0.5, "green"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin Whatsapp otomatik tahakkuk göndermesi açılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            9,
                            true
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    Whatsapp Tahakkuk Göndermeyi Aç
                  </Button>{" "}
                </>
              ) : (
                <>
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.1, "green"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.65, "green"),
                      "&:hover": {
                        backgroundColor: lighten(0.5, "green"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin E-posta otomatik beyanname göndermesi açılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            10,
                            true
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    E-posta Beyanname Göndermeyi Aç
                  </Button>{" "}
                  <Button
                    disabled={selectedIds?.length === 0}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: darken(0.1, "green"),
                      fontWeight: "700",
                      backgroundColor: lighten(0.65, "green"),
                      "&:hover": {
                        backgroundColor: lighten(0.5, "green"),
                      },
                      "&:disabled": {
                        background: lighten(0.3, "gray"),
                      },
                    }}
                    onClick={() => {
                      setAnchorElSub(null);
                      setAnchorEl(null);
                      Swal.fire({
                        text: "Seçilen mükelleflerin Whatsapp otomatik beyanname göndermesi açılacak.Devam etmek istiyor musunuz?",
                        icon: "question",
                        showDenyButton: true,
                        denyButtonText: "Vazgeç",
                        confirmButtonText: "Devam Et",
                      }).then((resp) => {
                        if (resp.isConfirmed) {
                          refreshGrid?.deselectAll();

                          handleAutoSendAccrualsAndDeclarations(
                            selectedIds,
                            11,
                            true
                          )
                            .then((resp) => {
                              Swal.fire({
                                text: resp.data.message,
                                icon: "success",
                                confirmButtonText: "Tamam",
                              });
                              setSelectedIds([]);
                            })
                            .catch((err) => {
                              Swal.fire({
                                title:
                                  err.response.data.message || "Hata oluştu",
                                icon: "error",
                                confirmButtonText: "Tamam",
                              });
                            });
                        }
                      });
                    }}
                    fullWidth
                  >
                    Whatsapp Beyanname göndermeyi Aç
                  </Button>{" "}
                </>
              )}
            </Popover>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TaxPayersPage;
